// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Finances_section_1XzbO{grid-template-rows:min-content min-content 1fr;height:100%;background-color:#f8f8f8}.Finances_header_3nT6o,.Finances_section_1XzbO{display:grid;width:100%}.Finances_header_3nT6o{align-content:center;justify-items:center;row-gap:6px;height:120px;background-color:var(--supster-secondary)}.Finances_title_2vjLp{color:var(--supster-primary);font-weight:500;font-size:36px;line-height:36px}.Finances_description_243Cb{color:#fff;font-weight:400;font-size:12px;line-height:18px}.Finances_tabs_1SALq{height:auto}.Finances_sort_1xj1S{padding:13px;box-shadow:inset 0 -1px 0 #000}.Finances_sort_1xj1S,.Finances_table_34TZ7{background-color:#fff}.Finances_table_34TZ7{min-height:200px}", ""]);
// Exports
exports.locals = {
	"section": "Finances_section_1XzbO",
	"header": "Finances_header_3nT6o",
	"title": "Finances_title_2vjLp",
	"description": "Finances_description_243Cb",
	"tabs": "Finances_tabs_1SALq",
	"sort": "Finances_sort_1xj1S",
	"table": "Finances_table_34TZ7"
};
module.exports = exports;
