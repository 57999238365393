<template>
  <div :class="$style.table">
    <div :class="$style.content">
      <div :class="[$style.wrap, $style.wrapHeader]">
        <div
          :class="[$style.item, $style.itemHeader]"
          v-for="(column, index) of columns"
          :key="`table-th-${index}`"
          :style="{ minWidth: getWidth(column.width) }"
        >
          {{ column.value }}
        </div>
      </div>
      <transition-group name="fade">
        <template v-if="data.length">
          <div :class="$style.wrap" v-for="(row, i) of transformData" :key="`table-row-${i}`">
            <div
              :class="$style.item"
              v-for="(item, j) of row"
              :key="`table-td-${j}`"
              :style="{ minWidth: getWidth(item.width) }"
            >
              <slot name="row" v-bind="{ row: item, index: i }">{{ item.value }}</slot>
            </div>
          </div>
        </template>
      </transition-group>
      <v-no-data v-if="noData" :class="$style.center" :key="data.length" />
    </div>
    <infinite-loading
      :identifier="infiniteId"
      slot="append"
      :distance="1000"
      @infinite="$emit('infinite', $event)"
    >
      <template slot="spinner">
        <content-loader :class="$style.center" :is-loading="!noData" :size="20" />
      </template>
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
  </div>
</template>
<script>
import Vue from 'vue'
import { v4 as uuid } from 'uuid'
import ContentLoader from '@loaders/list/ContentLoader.vue'

import VNoData from '@layouts/VNoData.vue'

export default Vue.extend({
  name: 'VTable',
  components: {
    ContentLoader,
    VNoData,
  },
  props: {
    id: [Number, String],
    columns: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    isLoading: Boolean,
    noData: Boolean,
  },
  data() {
    return {
      infiniteId: uuid(),
      transformData: {},
    }
  },
  watch: {
    data: {
      handler() {
        this.transformRowData()
      },
      immediate: true,
      deep: true,
    },
    id() {
      this.infiniteId = uuid()
    },
  },
  methods: {
    getWidth(width) {
      if (!width) {
        return null
      }
      return typeof width === 'string' ? width : `${width}px`
    },
    transformRowData() {
      if (this.data?.length) {
        this.transformData = this.data.reduce((acc, item, index) => {
          if (!acc[index] && !acc[index]?.length) {
            acc[index] = []
            for (const { name, width } of this.columns) {
              this.deepSearchByKey({ object: item, originalKey: name, matches: acc[index], width })
            }
          }
          return acc
        }, {})
      }
    },
    deepSearchByKey({ object, originalKey, matches = [], width }) {
      if (object != null) {
        if (Array.isArray(object)) {
          for (const arrayItem of object) {
            this.deepSearchByKey({ object: arrayItem, originalKey, matches, width })
          }
          return []
        }
        if (typeof object === 'object') {
          for (const key of Object.keys(object)) {
            if (key === originalKey) {
              matches.push({
                name: key,
                value: object[key],
                width,
              })
            }
          }
        }
      }
      return matches
    },
  },
})
</script>
<style lang="scss" module>
.table {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.content {
  position: relative;
  width: 100%;
  height: 100%;
}

.wrap {
  display: flex;
  width: 100%;

  &Header {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1001;
  }
}

.item {
  align-self: center;
  justify-self: center;
  width: 100%;
  min-width: 100px;
  padding: 13px 9px;
  overflow: hidden;
  color: $secondary;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-shadow: inset 0 -1px 0 $secondary-light;

  &Header {
    background-color: $primary;
    box-shadow: inset 0 -1px 0 transparent;
  }
}

.center {
  position: absolute;
  top: 50px;
  left: 50%;
  height: auto;
  transform: translateX(-50%);
}
</style>
