// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VTable_table_1EF_X{overflow:auto}.VTable_content_12i-r,.VTable_table_1EF_X{position:relative;width:100%;height:100%}.VTable_wrap_2QkPD{display:flex;width:100%}.VTable_wrapHeader_3vDWy{position:sticky;top:0;left:0;z-index:1001}.VTable_item_3mlZ0{align-self:center;justify-self:center;width:100%;min-width:100px;padding:13px 9px;overflow:hidden;color:var(--supster-secondary);font-size:12px;white-space:nowrap;text-overflow:ellipsis;box-shadow:inset 0 -1px 0 #e5e3e3}.VTable_itemHeader_1u_Ey{background-color:var(--supster-primary);box-shadow:inset 0 -1px 0 transparent}.VTable_center_1YVof{position:absolute;top:50px;left:50%;height:auto;transform:translateX(-50%)}", ""]);
// Exports
exports.locals = {
	"table": "VTable_table_1EF_X",
	"content": "VTable_content_12i-r",
	"wrap": "VTable_wrap_2QkPD",
	"wrapHeader": "VTable_wrapHeader_3vDWy",
	"item": "VTable_item_3mlZ0",
	"itemHeader": "VTable_itemHeader_1u_Ey",
	"center": "VTable_center_1YVof"
};
module.exports = exports;
