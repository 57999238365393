<template>
  <div :class="$style.section">
    <div :class="$style.header">
      <h1 :class="$style.title">{{ currency }}{{ getBalance || 0 }}</h1>
      <h1 :class="$style.description">{{ $t('currentBalance') }}</h1>
    </div>
    <div :class="$style.sort">
      <select-dropdown
        name="orders"
        color="transparent"
        icon-size="lg"
        :list="dates"
        :select-value="$t(`sortByDates.${sort}`)"
        @change="onChangeDate"
      />
    </div>
    <v-table
      :id="id"
      :class="$style.table"
      :columns="tableColumns"
      :data="income.data"
      :is-loading="isLoading"
      :no-data="income.noData"
      @infinite="onLoadHistory"
    >
      <template #row="{ row, index }">
        <div v-if="row.name === 'created'">
          {{ formatDate(row.value) }}
        </div>
        <div v-else-if="row.name === 'amount'">{{ formatCurrency(row.value, index) }}</div>
        <div v-else-if="row.name === 'app'">CC</div>
        <div v-else-if="row.name === 'status'">
          {{ $t('success.joinChannel.caption') }}
        </div>
      </template>
    </v-table>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import SelectDropdown from '@dropdowns/list/SelectDropdown.vue'

import currencies from '@utils/currencies'
import { formatDateRanges, formatDateToType } from '@utils/formatDate'

import VTable from '@elements/VTable.vue'

export default Vue.extend({
  name: 'Finances',
  components: {
    SelectDropdown,
    VTable,
  },
  data() {
    return {
      id: null,
      sort: 7,
      isLoading: false,
    }
  },
  beforeRouteLeave(_, __, next) {
    this.setPage(null)
    this.resetAllData()
    next()
  },
  computed: {
    ...mapState('locale', ['lang']),
    ...mapState('money', ['income']),
    ...mapGetters('apps', ['getCurrentAppId', 'getCurrencyCode', 'getCurrentAppsUser']),
    ...mapGetters('balance', ['getBalance']),
    userId() {
      return this.getCurrentAppsUser?.id ?? null
    },
    tableColumns() {
      return [
        {
          name: 'id',
          value: 'ID',
          width: 40,
        },
        {
          name: 'created',
          value: this.$t('table.date'),
        },
        {
          name: 'transactionId',
          value: this.$t('receiver'),
        },
        {
          name: 'app',
          value: this.$t('method'),
        },
        {
          name: 'amount',
          value: this.$t('table.sum'),
        },
        {
          name: 'status',
          value: this.$t('table.status'),
        },
      ]
    },
    dates() {
      return Array.from(Array(8).keys()).map((item) => ({
        item: String(item),
        value: this.$t(`sortByDates.${item}`),
      }))
    },
    currency() {
      return currencies[this.getCurrencyCode]?.symbol
    },
  },
  created() {
    this.setPage({
      name: 'finances',
      translation: {
        name: 'aside.finances',
      },
    })
  },
  methods: {
    ...mapMutations(['setPage']),
    ...mapMutations('money', ['resetAllData']),
    ...mapActions('money', ['loadMoney']),
    async onLoadHistory(scroll) {
      this.isLoading = true
      await this.loadMoney({
        scroll,
        date: formatDateRanges(this.sort, this.lang),
        appId: this.getCurrentAppId,
        type: 'income',
        userId: this.userId,
      })
      this.isLoading = false
    },
    async onChangeDate({ item }) {
      this.sort = item
      this.resetAllData()
      this.id = +new Date()
    },
    formatCurrency(amount, index) {
      const code = this.income.data[index]?.currency?.code
      if (code) {
        return `${currencies[code]?.symbol ?? code} ${amount} `
      }
      return amount
    },
    formatDate(date) {
      return formatDateToType(date, 'MM/DD HH:HH', this.lang)
    },
    getCurrency(index) {
      return this.income.data[index]?.currency?.code ?? null
    },
  },
})
</script>
<style lang="scss" module>
.section {
  display: grid;
  grid-template-rows: min-content min-content 1fr;
  width: 100%;
  height: 100%;
  background-color: $shadow;
}

.header {
  display: grid;
  align-content: center;
  justify-items: center;
  row-gap: 6px;
  width: 100%;
  height: 120px;
  background-color: $secondary;
}

.title {
  color: $primary;
  font-weight: 500;
  font-size: 36px;
  line-height: 36px;
}

.description {
  color: #ffffff;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.tabs {
  height: auto;
}

.sort {
  padding: 13px;
  background-color: white;
  box-shadow: inset 0 -1px 0 black;
}

.table {
  min-height: 200px;
  background-color: white;
}
</style>
